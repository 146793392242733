<template>
    <div class="p-12 text-center">
        <span v-if="!isLogin">自動登入中，請稍候</span>
        <span v-if="isLogin && loginSuccess">登入成功，將自動跳轉至登入頁面</span>
        <span v-if="isLogin && !loginSuccess">登入失敗，回到登入頁</span>
    </div>
</template>
<script>
import axios from 'axios'
import { theseAutoLogin, getUIAPartnrtList } from '@/api/user.js'
export default {
    data() {
        return {
            isLogin: false,
            loginSuccess: true,
            partner_id: 0,
            queue_id: 0,
            pid: 0,
            token: '',
        }
    },
    mounted() {
        this.partner_id = this.$route.query.partner_id
        this.queue_id = this.$route.query.queue_id
        this.pid = this.$route.query.pid
        this.token = this.$route.query.token
        localStorage.setItem('partnerid', this.partner_id)
        this.autoLogin()
    },
    computed: {
        deviceType() {
            return localStorage.getItem('device_type')
        },
        deviceToken() {
            return localStorage.getItem('device_token')
        },
    },
    methods: {
        autoLogin() {
            const payload = {
                token: this.token,
                phoneType: this.deviceType,
                voipToken: this.deviceToken,
                apnsToken: this.deviceToken,
            }
            theseAutoLogin(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.isLogin = true
                        localStorage.setItem('jwt', res.data.data.jwt)
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.data.jwt
                    } else {
                        // 拒絕 Promise，跳過後續的 .then()，進入 .catch()
                        return Promise.reject('Login failed: status not OK')
                    }
                })
                .then(() => {
                    this.getPartnerList()
                    this.$store.dispatch('updateDoctorStatus', 1000)

                    const _self = this
                    const setTime = setInterval(function () {
                        _self.$router.push('/emr2/' + _self.pid + '/' + _self.queue_id)
                        clearInterval(setTime)
                    }, 2000)
                })
                .catch((error) => {
                    console.error('Login failed:', error)
                    this.loginSuccess = false
                    location.reload()
                })
        },
        // 取該帳號所有的合作夥伴ID (is_uia參數使用 待確認)
        getPartnerList() {
            getUIAPartnrtList({ is_uia: 0 }).then((res) => {
                if (res.data.status != 'success') return 'get partner api error!'
                localStorage.setItem('partnerlist', JSON.stringify(res.data.results))
            })
        },
    },
}
</script>
